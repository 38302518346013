@import 'assets/_vars_login';

html {
	font-size:62.5%;
}

body.login {
background: white;
	div#login{
	    width: 350px;
	    padding: 0;
	    margin: auto;
	    top: 50%;
	    position: absolute;
	    left: 50%;
	    transform: translate(-50%, -50%);		
		h1 a {
			background-image: url('login-logo.svg');
			margin: 0 auto;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
			display: block;
			font-size: 20px;
			height: 100px;
			line-height: 1.3em;
			outline: 0 none;
			padding: 0;
			text-indent: -9999px;
			width: 100%;
			margin-bottom: 20px;
		}
		
		label {
		  font-size: 12px;
		  color: #555555;
		}
		
		input[type="text"]{
		  background-color: #ffffff;
		  border-color:#dddddd;
		  -webkit-border-radius: 4px;
		}
		form{
			margin-top: 0;
			box-shadow: 0 5px 25px -10px rgba(0,0,0,.5);
		}
		input[type="password"]{
		  background-color: #ffffff;
		  border-color:#dddddd;
		  -webkit-border-radius: 4px;
		}
		.wp-core-ui .button-group.button-large .button, .wp-core-ui .button.button-large,
		.button-primary.button {
			@include button();
			box-shadow: none;
			text-shadow: none;
			height: auto;
			font-size: 1.2rem;				
			&:hover,
			&:active {
				@include buttonhover();
			}
		}
	}
}