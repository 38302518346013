/*------------------------------------*\
    SCSS
\*------------------------------------*/
*{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}
// colors
$logo-black: black;
$body-color: black;
$title-color: #323232;
$grey-dark: #363636;
$grey-light:#e1e1e1;
$grey-superlight:#f5f5f5;
$grey: $body-color;
//effects
$shadow-material: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
$shadow-material-hover: 0 2px 5px 0 rgba(0, 0, 0, 0.6);
$shadow-text: 0 2px 5px rgba(0, 0, 0, 0.5);

//fonts
$regular: 400;
$bold: 600;
$bold: 700;
$extra-bold:800;

/*
$font-body: $regular 1.4rem/1.4 "Open Sans", Helvetica, Arial, sans-serif;
$font-titles: $extra-bold 1.4rem/1.4 "Raleway", Helvetica, Arial, sans-serif;
$font-button: $bold 1.4rem/1.4 "Open Sans", Helvetica, Arial, sans-serif;
*/

$h1: 4rem;
$h2: $h1 * 0.8;
$h3: $h1 * 0.6;
$h4: $h1 * 0.5;
$h5: $h1 * 0.4;
//transforms
$centerXY:  translate(-50%, -50%);
$scaleup: scale(1.2);
//padding
$pad1: 1rem;
$pad2: 2rem;
$pad3: 3rem;
$pad4: 4rem;
$pxy: 8rem 0;
//transitions
@mixin transition($transition) {
	-webkit-transition: $transition;
	   -moz-transition: $transition;
	     -o-transition: $transition;
		    transition: $transition;
}
@mixin transform($transform) {
	-webkit-transform: $transform;
	   -moz-transform: $transform;
	     -o-transform: $transform;
		    transform: $transform;
}
$slow: all 0.3s ease;
$fast: all 0.15s ease;
$cubic:  300ms cubic-bezier(.4, 0, .2, 1);
//shapes
@mixin radius($deg) {
	border-radius: $deg;
}
@mixin square($size) {
	height: $size;
	width: $size;
}
@mixin circle($size) {
	height: $size;
	width: $size;
	@include radius(50%);
}
@mixin blocktitle(){
/*
	font: $font-titles;
	font-weight: 500;
	font-size: 2rem;
	padding: .5rem;
	display: inline-block;
	margin: 0 auto;
	max-width: 300px;
	border-bottom: 1px solid $body-color;
	border-top: 1px solid $body-color;

*/
	text-align: center;
	margin: 3rem auto 2rem;
	width: auto;
	display: inline-block;
	max-width: 90%;
	padding: 0.8rem 2rem;
	border-top: 1px solid $body-color;
	border-bottom: 1px solid $body-color;

}
@mixin cattitle(){
	text-align: center;
	margin: 3rem auto 2rem;
	width: auto;
	display: inline-block;
	max-width: 90%;
	padding: 0.8rem 2rem;
	border-top: 1px solid $body-color;
	border-bottom: 1px solid $body-color;
}

@mixin linearGradient($top, $bottom){
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}


@mixin positioning($position, $top: null, $right: null, $bottom: null, $left: null, $width: null, $height: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  width: $width;
  height: $height;
}
@mixin flexbox($row, $wrap){
	display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box ;         /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;      /* TWEENER - IE 10 */
	display: -webkit-flex;     /* NEW - Chrome */
	display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
	-ms-flex-direction: $row;
	-ms-flex-wrap: $wrap;
	-webkit-flex-direction: $row;
	-webkit-flex-flow: $wrap;
	flex-flow: $row $wrap;
}

@mixin button(){
	// font: $font-button;
	font-size: 1.6rem;
	line-height: 1;
	display: inline-block;
	padding: 1rem 1.5rem;
	border: 1px solid $logo-black;
	transition: $slow;
	background: white;
	color: black;
	text-transform: uppercase;
	font-weight: bold;
	border-radius: 0;
}
@mixin buttonhover(){
    background: black;
    color: white;
}
@mixin centerelement() {
	position: absolute;
	left: 50%;
	top:50%;
	@include transform($centerXY);
}
@mixin abstopleft() {
 position: absolute;
 top: 0;
 left: 0;
}
@mixin abstopright() {
 position: absolute;
 top: 0;
 right: 0;
}

@mixin clear() {
	&:before,
	&:after {
	    content:' ';
	    display:table;
	}
	
	&:after {
	    clear:both;
	}
    *zoom:1;
}


//sfondi
.bg-grey{
	background: $body-color;
}
//position relative
.relative{
	position: relative;
}
.txj{text-align:justify}
.txc{text-align:center}
.uc{text-transform:uppercase}
.txr{text-align:right;}
.txl{text-align: left;}


.btn,
.button{
	@include button();
	&:hover{
		@include buttonhover();
	}
	&.white{
	    border-color: white;
	    background: white;
	    color: $logo-black;
	}
	&.white:hover,
	&.white.active{
	    border-color: white;
	    background: $logo-black;
	    color: white;
	}
	&.white.trans{
	    border-color: white;
	    background: transparent;
	    color: white;
	}
	&.white.trans:hover,
	&.white.trans.active{
	    border-color: white;
	    background: white;
	    color: $logo-black;
	}
	&.trans{
	    background: transparent;
	    color: $logo-black;
	}
	&.trans:hover,
	&.trans.active{
	    background: $logo-black;
	    color: white;
	}
	&.grey{
	    border-color: $grey-dark;
	    background: $grey-dark;
	    color: white;
	}
	&.grey:hover,
	&.grey.active{
	    border-color: $grey-dark;
	    background: transparent;
	    color: $grey-dark;
	}
	&.grey.trans{
	    border-color: $grey-dark;
	    background: transparent;
	    color: $grey-dark;
	}
	&.grey.trans:hover,
	&.grey.trans.active{
	    border-color: $grey-dark;
	    background: $grey-dark;
	    color: white;
	}
}
.btn.iconed{
	position: relative;
	padding-left: 5.5rem;
	padding-right: 4rem;
	[class^="icon-"],
	[class*=" icon-"]{
		@include positioning(absolute, $top:50%,$left:3rem);
		transform: translateY(-50%);
		font-size: 1.6em;
	}
}